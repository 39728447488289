<template>
  <div class="incidents-table">
    <v-data-table
        :headers="header"
        :items="items"
        :options.sync="paginationOptions"
        :server-items-length="totalItems"
        :loading="loading"
        :footer-props="{
          itemsPerPageOptions: [10,25,50]
        }"
        :height="tableHeight"
        hide-default-header
        fixed-header
        no-data-text="No data available, please try to change search filters"
    >
      <template v-slot:header="{ props: { headers } }">
        <thead
          class="v-data-table-header"
        >
          <tr
            v-if="!loading || items.length"
            class="fixed sections"
          >
            <th
                class="text-center sequence-number"
                rowspan="2"
            >
              SequenceNumber
            </th>
            <th
                v-if="isDeletedIncidentsType"
                class="text-center"
                :colspan="deletedIncidentsHeader.length"
            ></th>
            <th
                v-if="isUnfinishedIncidentsType"
                class="text-center"
                :colspan="unfinishedIncidentsHeader.length"
            ></th>
            <th
                v-if="showFlaggedIncidentsColumns"
                class="text-center"
                :colspan="flaggedIncidentsHeader.length"
            ></th>
            <th
                v-for="(count, name) in sections"
                :key="name"
                class="text-center"
                :colspan="count"
            >
              {{ name }}
            </th>
          </tr>
          <tr
            v-if="!loading || items.length"
            class="fields"
          >
            <th
                v-for="header in headers.filter(function (value) {
                return value.hidden !== true;
              })"
                :key="header.text"
                :class="[
                  header.sortable ? 'sortable' : '',
                  header.value === pagination.sortBy[0] ? 'active' : '',
                  header.align === 'left' ? 'text-left' : '',
                  pagination.sortDesc[0] ? 'desc': 'asc'
                ]"
                :style="header.width ? 'min-width: ' + header.width + 'px;' : null"
                class="field-col text-left"
                @click="header.sortable ? changeSort(header.value) : null"
            >
              <span v-html="header.text"></span>
              <v-icon
                v-if="header.sortable"
                class="v-data-table-header__icon"
                small
                >mdi-arrow-up</v-icon>
            </th>
          </tr>
        </thead>
      </template>

      <template v-slot:item.II_SequenceNumber="{ item }">
        <div class="first-col">
          <v-tooltip
            v-if="$can('viewIncidentDialog', 'incident') && isNotUploaded(item)"
            bottom
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn

                  icon
                  class="float-left mr-1"
                  color="warning"
                  v-bind="attrs"
                  v-on="on"
              >
                <v-icon>mdi-alert-outline</v-icon>
              </v-btn>
            </template>
            <span>Pending a complete upload</span>
          </v-tooltip>

          <v-btn
              v-else-if="$can('viewIncidentDialog', 'incident')"
              icon
              class="float-left mr-1"
              @click="$emit('view-incident', item)"
          >
            <v-icon>mdi-file-image</v-icon>
          </v-btn>

          <span class="float-left mt-2">
            <router-link
              v-if="showViewIncidentLink && $can('view', 'incident')"
              :to="{ name: routes.INCIDENT_VIEW, params: { id: item.id }}"
            ><b>{{ item.II_SequenceNumber }}</b></router-link>
            <b v-else>{{ item.II_SequenceNumber }}</b>
          </span>

          <div v-if="!isNotUploaded(item)">
            <v-menu
              v-if="$can('showContextMenu', 'incident')
                    && (isDeletedIncidentsType || isUnfinishedIncidentsType)"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    icon
                    v-bind="attrs"
                    class="float-right"
                    v-on="on"
                >
                  <v-icon>mdi-dots-horizontal</v-icon>
                </v-btn>
              </template>
              <v-list dense>
                <v-list-item
                    v-if="$can('view', 'incident')"
                    @click="$emit('view-incident', item)"
                >
                  <v-list-item-icon>
                    <v-icon>
                      mdi-file-eye-outline
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>View</v-list-item-title>
                </v-list-item>

                <v-list-item
                    v-if="item.IsUnique"
                    @click="$emit('commit-to-main-db', item)"
                >
                  <v-list-item-icon>
                    <v-icon>
                      mdi-database-import-outline
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Commit to Main DB</v-list-item-title>
                </v-list-item>
                <v-list-item
                    v-else
                    @click="$emit('overwrite-incident', item)"
                >
                  <v-list-item-icon>
                    <v-icon>
                      mdi-file-replace-outline
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Overwrite previous record</v-list-item-title>
                </v-list-item>

                <v-divider v-if="$can('delete', 'incident')"/>
                <v-list-item
                    v-if="$can('delete', 'incident')"
                    @click="deleteIncident(item)"
                >
                  <v-list-item-icon>
                    <v-icon>
                      mdi-trash-can-outline
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Delete Forever</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            <v-menu v-else-if="$can('showContextMenu', 'incident')">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    icon
                    v-bind="attrs"
                    class="float-right"
                    v-on="on"
                >
                  <v-icon>mdi-dots-horizontal</v-icon>
                </v-btn>
              </template>

              <v-list dense>
                <v-list-item
                    v-if="showViewIncidentLink && $can('view', 'incident')"
                    :to="{ name: routes.INCIDENT_VIEW, params: { id: item.id }}"
                >
                  <v-list-item-icon>
                    <v-icon>
                      mdi-file-eye-outline
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>View</v-list-item-title>
                </v-list-item>
                <v-list-item
                    v-if="showViewIncidentLink && $can('edit', 'incident') && !item.IsReadOnly"
                    :to="{ name: routes.INCIDENT_VIEW, params: { id: item.id }, hash: '#edit'}"
                >
                  <v-list-item-icon>
                    <v-icon>
                      mdi-pencil
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Edit</v-list-item-title>
                </v-list-item>
                <v-list-item
                    v-if="canAddFlag(item)"
                    @click="$emit('add-flag', item)"
                >
                  <v-list-item-icon>
                    <v-icon>
                      mdi-flag-variant
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Flag</v-list-item-title>
                </v-list-item>
                <v-list-item
                    v-if="isFlaggedByCurrentUser(item)
                  && getMinStatus(item.flag.alerts) === flagStatuses.PENDING"
                    @click="resendPendingAlerts(item)"
                >
                  <v-list-item-icon>
                    <v-icon>
                      mdi-email-sync-outline
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Resend Pending</v-list-item-title>
                </v-list-item>
                <v-list-item
                    v-if="$store.getters.isImpersonated"
                    @click="downloadJson(item)"
                >
                  <v-list-item-icon>
                    <v-icon>
                      mdi-download
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Download JSON</v-list-item-title>
                </v-list-item>
                <v-list-item
                    v-if="$store.getters.isImpersonated"
                    @click="regeneratePdf(item)"
                >
                  <v-list-item-icon>
                    <v-icon>
                      mdi-cached
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Regenerate</v-list-item-title>
                </v-list-item>
                <v-divider v-if="$can('delete', 'incident')"/>
                <v-list-item
                    v-if="$can('delete', 'incident')"
                    @click="deleteIncident(item)"
                >
                  <v-list-item-icon>
                    <v-icon>
                      mdi-trash-can-outline
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Delete</v-list-item-title>
                </v-list-item>

              </v-list>
            </v-menu>

            <v-icon
                v-if="$can('showFlagIcon', 'incident') && item.flag && !canAddFlag(item)"
                :color="getFlagColor(item.flag.alerts)"
                class="float-right mt-2"
            >
              mdi-flag-variant
            </v-icon>
            <v-btn
                v-if="$can('showFlagIcon', 'incident') && item.flag && canAddFlag(item)"
                icon
                :color="getFlagColor(item.flag.alerts)"
                class="float-right"
                @click.stop.prevent="$emit('add-flag', item)"
            >
              <v-icon>mdi-flag-variant</v-icon>
            </v-btn>
          </div>
        </div>
      </template>

      <template
          v-for="field in dateTimeFields"
          v-slot:[`item.${field}`]="{ value }"
      >
        {{ value | datetime }}
      </template>
      <template
          v-for="field in signatureFields"
          v-slot:[`item.${field}`]="{ value }"
      >
        {{ value ? 'Yes' : 'No' }}
      </template>
      <template v-slot:item.IsUnique="{ item }">
        {{ item.IsUnique ? 'Unique' : 'Duplicate' }}
      </template>
      <template v-slot:item.RemovedAt="{ item }">
        {{ item.RemovedAt | datetimeToClientTimezone }}
      </template>
      <template v-slot:item.FlaggedAt="{ item }">
        {{ item.flag.createdAt | date }}
      </template>
      <template v-slot:item.FlagStatus="{ item }">
        {{ getStatus(item.flag.alerts) }}
      </template>
      <template v-slot:item.FlaggedUsers="{ item }">
        <span class="user-names">
          <template v-for="(alert, index) in item.flag.alerts">
            <span
                v-bind:key="'alert-' + index"
                :class="statusClassMap[alert.status]"
            >{{ alert.fullName }}</span><span
              v-if="index < item.flag.alerts.length-1"
              v-bind:key="'comma-' + index"
          >, </span>
          </template>
        </span>
      </template>
      <template v-slot:item.IncidentType="{ item }">
        {{ item.IncidentType | incidentType }}
      </template>
    </v-data-table>
    <confirmation ref="deleteConfirmationPopup">
      <v-card-text
        v-if="isUnfinishedIncidentsType"
        class="pb-0"
      >
        <v-checkbox
            v-model="deleteOnDevice"
            hide-details
            label="Delete on device?"
        ></v-checkbox>
      </v-card-text>
    </confirmation>
  </div>
</template>

<script>
import _ from 'lodash';
import fileDownload from 'js-file-download';
import { mapActions } from 'vuex';
import flaggedUsers from '@/api/flaggedUsers';
import incidents from '@/api/incidents';
import fixedHeader from '@/mixins/fixedHeader';
import INCIDENT_FIELDS from '@/enums/incidentFields';
import ROUTES from '@/enums/routes';
import FLAG_STATUSES from '@/enums/flagStatuses';
import { SHOW_SNACKBAR } from '@/store/actions';
import Confirmation from '@/components/Confirmation';
import incidentFlagMixins from '@/mixins/incidentFlagMixins';

export default {
  name: 'IncidentsTable',
  components: { Confirmation },
  mixins: [fixedHeader, incidentFlagMixins],

  filters: {
    incidentType(types) {
      let typeList = types;
      if (typeList) {
        typeList = _.map(
          types,
          type => type.toLowerCase().replace(/\b[a-z]/g, (letter) => letter.toUpperCase()),
        );
      }
      return typeList ? typeList.join(', ') : '';
    },
  },

  props: {
    totalItems: {
      type: Number,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    tableFields: {
      type: Array,
      required: true,
    },
    selectedFields: {
      type: Array,
      required: true,
    },
    pagination: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    isDeletedIncidentsType: {
      type: Boolean,
      default: false,
    },
    isUnfinishedIncidentsType: {
      type: Boolean,
      default: false,
    },
    showFlaggedIncidentsColumns: {
      type: Boolean,
      default: false,
    },
    showViewIncidentLink: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      paginationOptions: this.pagination,
      statusClassMap: {
        0: 'pending',
        1: 'understood',
        2: 'not-understand',
      },
      flagStatuses: FLAG_STATUSES,
      flagLoading: false,
      routes: ROUTES,
      additionalIndent: 0,
      deleteOnDevice: false,
    };
  },
  computed: {
    tableSelectedFields() {
      const data = [];
      const selectedFields = _.clone(this.selectedFields);
      selectedFields.push(INCIDENT_FIELDS.SEQUENCE_NUMBER,
        INCIDENT_FIELDS.INCIDENT_NUMBER,
        INCIDENT_FIELDS.INCIDENT_DATE);
      this.tableFields.forEach((category) => {
        const categoryData = {
          categoryName: category.categoryName,
          currentFields: [],
          historicalFields: [],
        };
        category.currentFields.forEach(fieldData => {
          if (fieldData.field === INCIDENT_FIELDS.SEQUENCE_NUMBER) {
            return;
          }
          if (selectedFields.indexOf(fieldData.field) !== -1) {
            categoryData.currentFields.push(fieldData);
          }
        });
        category.historicalFields.forEach(fieldData => {
          if (selectedFields.indexOf(fieldData.field) !== -1) {
            categoryData.historicalFields.push(fieldData);
          }
        });
        if (categoryData.currentFields.length || categoryData.historicalFields.length) {
          data.push(categoryData);
        }
      });
      return data;
    },

    sections() {
      const sections = {};
      this.tableSelectedFields.forEach((category) => {
        sections[category.categoryName] = category.currentFields.length
            + category.historicalFields.length;
      });
      return sections;
    },

    /**
     * List of deleted incident columns.
     *
     * @return {Array}
     */
    deletedIncidentsHeader() {
      return [
        {
          text: 'Is Unique',
          value: 'IsUnique',
          sortable: true,
        },
        {
          text: 'Date Removed',
          value: 'RemovedAt',
          sortable: true,
        },
      ];
    },

    /**
     * List of unfinished incident columns.
     *
     * @return {Array}
     */
    unfinishedIncidentsHeader() {
      return [
        {
          text: 'Is Unique',
          value: 'IsUnique',
          sortable: true,
        },
        {
          text: 'Device',
          value: 'Device',
          sortable: false,
        },
      ];
    },

    /**
     * List of flagged incident columns.
     *
     * @return {Array}
     */
    flaggedIncidentsHeader() {
      return [
        {
          text: 'Date Flagged',
          value: 'FlaggedAt',
          sortable: true,
        },
        {
          text: 'Flag Status',
          value: 'FlagStatus',
          width: 180,
        },
        {
          text: 'Flagged Users',
          value: 'FlaggedUsers',
          width: 300,
        },
      ];
    },

    header() {
      let header = [];

      if (this.tableSelectedFields.length) {
        header.push({
          text: 'SequenceNumber',
          value: INCIDENT_FIELDS.SEQUENCE_NUMBER,
          hidden: true,
        });
      }

      if (this.isDeletedIncidentsType) {
        header = header.concat(this.deletedIncidentsHeader);
      }
      if (this.isUnfinishedIncidentsType) {
        header = header.concat(this.unfinishedIncidentsHeader);
      }
      if (this.showFlaggedIncidentsColumns) {
        header = header.concat(this.flaggedIncidentsHeader);
      }

      this.tableSelectedFields.forEach(category => {
        category.currentFields.forEach(fieldData => {
          header.push({
            text: fieldData.name,
            value: fieldData.field,
            sortable: true,
          });
        });
        category.historicalFields.forEach(fieldData => {
          header.push({
            text: fieldData.name,
            value: fieldData.field,
            sortable: true,
          });
        });
      });
      return header;
    },
    dateTimeFields() {
      const fields = [];
      this.tableSelectedFields.forEach(category => {
        category.currentFields.forEach(fieldData => {
          if (fieldData.type === 'datetime') {
            fields.push(fieldData.field);
          }
        });
        category.historicalFields.forEach(fieldData => {
          fields.push(fieldData.field);
        });
      });
      return fields;
    },
    signatureFields() {
      const fields = [];
      this.tableSelectedFields.forEach(category => {
        category.currentFields.forEach(fieldData => {
          if (fieldData.type === 'has-signature') {
            fields.push(fieldData.field);
          }
        });
      });
      return fields;
    },
  },
  watch: {
    /**
     * Watch pagination options and update pagination props.
     */
    paginationOptions() {
      this.$emit('update:pagination', this.paginationOptions);
    },
  },
  methods: {
    ...mapActions({
      showSnackbar: SHOW_SNACKBAR,
    }),

    /**
     * Delete incident with confirmation.
     *
     * @param {Object} incident
     */
    deleteIncident(incident) {
      this.deleteOnDevice = false;
      this.$refs.deleteConfirmationPopup.showConfirm(
        'Confirmation',
        `Do you really want to delete incident "${incident[INCIDENT_FIELDS.SEQUENCE_NUMBER]}"?`,
        async () => {
          await incidents.delete(incident.id, this.deleteOnDevice);
          this.$emit('incident-deleted');
        },
      );
    },

    /**
     * Resend pending alerts.
     *
     * @param {Object} incident
     */
    async resendPendingAlerts(incident) {
      await flaggedUsers.resendPendingAlerts(incident.id);
      this.showSnackbar('Alerts successfully re-sent.');
    },

    /**
     * Handle for regenerate PDFs.
     *
     * @param {Object} incident
     */
    async regeneratePdf(incident) {
      await incidents.regeneratePdf(incident.id);
      this.showSnackbar('Regeneration process has started.');
    },

    /**
     * Handle for download JSON.
     *
     * @param {Object} incident
     */
    async downloadJson(incident) {
      const response = await incidents.downloadJson(incident.id);
      fileDownload(response.data, `${incident[INCIDENT_FIELDS.SEQUENCE_NUMBER]}.json`);
    },

    /**
     * Get text of minimal status of alerts.
     *
     * @param {Array} alerts
     *
     * @return {string|null}
     */
    getStatus(alerts) {
      const statusMap = {
        0: 'Needs To Be Reviewed',
        1: 'Reviewed',
        2: 'Reviewed',
      };
      const minStatus = this.getMinStatus(alerts);
      return minStatus !== null ? statusMap[minStatus] : null;
    },

    getFlagColor(alerts) {
      const minStatus = this.getMinStatus(alerts);
      const maxStatus = this.getMaxStatus(alerts);
      const advStatus = (minStatus === FLAG_STATUSES.UNDERSTOOD
              && maxStatus === FLAG_STATUSES.DID_NOT_UNDERSTAND
      ) || minStatus === FLAG_STATUSES.DID_NOT_UNDERSTAND;
      if (minStatus === FLAG_STATUSES.PENDING) {
        return 'red lighten-1';
      }
      if ([FLAG_STATUSES.UNDERSTOOD, FLAG_STATUSES.DID_NOT_UNDERSTAND].indexOf(minStatus) !== -1
          && advStatus) {
        return 'yellow accent-4';
      }
      return '';
    },

    changeSort(column) {
      const pagination = _.clone(this.paginationOptions);
      if (pagination.sortBy[0] === column) {
        if (pagination.sortDesc[0] === true) {
          pagination.sortBy = [];
          pagination.sortDesc = [];
        } else {
          pagination.sortDesc = [true];
        }
      } else {
        pagination.sortBy = [column];
        pagination.sortDesc = [false];
      }
      this.paginationOptions = pagination;
    },

    /**
     * Check if incident is not completed uploaded.
     *
     * @param {Object} item Item of table row
     *
     * @return {Boolean}
     */
    isNotUploaded(item) {
      return Object.prototype.hasOwnProperty.call(item, 'Uploaded') && !item.Uploaded;
    },
  },
};
</script>

<style lang="scss">
  .incidents-table {
    .sequence-number {
      z-index: 100 !important;
    }
    .field-col {
      white-space: nowrap;
    }
    table > tbody > tr > td:nth-child(1),
    table > thead > tr.fixed > th:nth-child(1) {
      position: sticky !important;
      position: -webkit-sticky !important;
      left: 0;
      z-index: 1;
      background: #f5f5f5;
      border-right: 1px solid rgba(0, 0, 0, 0.12);
    }
    table > thead > tr.fixed > th:nth-child(1) {
      z-index: 3;
      width: 240px;
    }
    table > thead tr.sections th {
      border-right: 1px solid rgba(0, 0, 0, 0.12);
      background: #f5f5f5 !important;
      height: 30px;
    }
    table > thead tr.fields th{
      top: 30px !important;
    }
    table > thead tr.sections th:nth-last-child(1) {
      border-right: none;
    }
    .first-col {
      width: 240px;
      white-space: nowrap;
      button {
        margin-left: 10px;
        i {
          cursor: pointer;
        }
      }
    }
    .user-names {
      .pending {
        color: #860000;
      }
      .understood, .not-understand {
        color: #006b00;
      }
    }
  }
</style>
