<template>
  <v-layout row>
    <v-flex
      xl4
      lg5
      md8
      sm12
      xs12
    >
      <v-list>
        <v-list-item-group>
          <v-list-item
            v-for="(version, index) in versions"
            :key="index"
            @click="clickByVersion"
          >
            <v-list-item-action>
              <version-actions :version="version"></version-actions>
            </v-list-item-action>
            <v-list-item-content :key="index">
              <v-list-item-title>
                {{ version.name }}<span v-if="version.installed"> <b> (installed now)</b></span>
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action v-if="!version.installed">
              <v-btn
                icon
                ripple
                @click="deleteApp(version.name)"
              >
                <v-icon color="grey darken-2">delete</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-flex>
    <confirmation
      ref="confirmationPopup"
      @confirmed="FETCH_VERSIONS()"
    ></confirmation>
  </v-layout>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { FETCH_VERSIONS } from '@/store/IosInstall/actions';
import VersionActions from '@/components/IosInstall/VersionActions';
import Confirmation from '@/components/Confirmation';
import i18n from '@/lang/i18n';
import { deleteVersion } from '@/api/iosInstall';

export default {
  name: 'AppVersions',
  components: {
    VersionActions,
    Confirmation,
  },
  computed: {
    ...mapState('iosInstall', ['versions']),
  },
  created() {
    this.FETCH_VERSIONS();
  },
  methods: {
    ...mapActions({
      FETCH_VERSIONS: `iosInstall/${FETCH_VERSIONS}`,
    }),
    deleteApp(version) {
      this.$refs.confirmationPopup.showConfirm(
        i18n.t('iosInstall.deleteConfirmationTitle'),
        i18n.t('iosInstall.deleteConfirmationText', { version }),
        async () => {
          await deleteVersion(version);
        },
      );
    },
    clickByVersion() {},
  },
};
</script>
