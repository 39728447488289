<template>
  <v-card
    elevation="3"
    class="ma-6 pa-6"
  >
    <page-url></page-url>
    <br>
    <app-versions></app-versions>
  </v-card>
</template>

<script>
import { SET_TITLE } from '@/store/mutations';
import AppVersions from '@/components/IosInstall/AppVersions';
import PageUrl from '@/components/IosInstall/PageUrl';

export default {
  components: {
    AppVersions,
    PageUrl,
  },
  created() {
    this.$store.commit(SET_TITLE, this.$store.state.iosInstall.title);
  },
};
</script>
