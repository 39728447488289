import actions from './actions';
import mutations from './mutations';
import getters from './getters';

export default {
  namespaced: true,
  state: {
    title: 'iOS App Versions',
    versions: [],
    url: null,
  },
  actions,
  mutations,
  getters,
};
