<template>
  <div>
    <v-btn
      :loading="loading"
      :disabled="loading"
      icon
      ripple
      @click.stop="showConfirmation"
    >
      <v-icon
        v-if="!version.installed"
        color="lighten-1"
      >radio_button_unchecked</v-icon>
      <v-icon
        v-if="version.installed"
        color="lighten-1"
      >radio_button_checked</v-icon>
    </v-btn>

    <v-dialog
      v-model="dialog"
      max-width="290"
    >
      <v-card>
        <v-card-title class="headline">Confirmation!</v-card-title>
        <v-card-text>
          Are you sure that you want to install this version?
          <v-form>
            <v-layout wrap>
              <v-flex xs12>
                <v-select
                    v-model="iosVersion"
                    v-validate="{ required: true }"
                    :items="iosVersions"
                    :error-messages="errors.collect('iOS Version')"
                    label="Select iOS Version"
                    item-value="value"
                    item-text="name"
                    name="iOS Version"
                ></v-select>
              </v-flex>
            </v-layout>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn
            color="red darken-1"
            text
            @click.native="dialog = false"
          >No</v-btn>
          <v-btn
            color="green darken-1"
            text
            @click.native="install"
          >Yes</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { setVersion } from '@/api/iosInstall';
import { FETCH_VERSIONS } from '@/store/IosInstall/actions';
import errorAlertMixins from '@/mixins/errorAlertMixins';

export default {
  name: 'VersionActions',
  filters: {},
  props: {
    version: {
      type: Object,
      required: true,
    },
  },
  mixins: [errorAlertMixins],
  data() {
    return {
      dialog: false,
      loading: false,
      iosVersion: null,
      iosVersions: [
        {
          name: 'iOS 15',
          value: 15,
        },
        {
          name: 'iOS 14',
          value: 14,
        },
        {
          name: 'iOS 13',
          value: 13,
        },
        {
          name: 'iOS 12',
          value: 12,
        },
      ],
      dictionary: {
        attributes: {
          iosVersion: 'iOS Version',
        },
      },
    };
  },
  methods: {
    ...mapActions({
      FETCH_VERSIONS: `iosInstall/${FETCH_VERSIONS}`,
    }),
    /**
     * Show confirmation dialog
     */
    showConfirmation() {
      this.$validator.reset();
      if (!this.version.installed) {
        this.dialog = true;
      }
    },
    /**
     * Call api method for install version
     *
     * @return {null}
     */
    async install() {
      await this.$validator.reset();
      this.errors.clear();
      if (!await this.$validator.validateAll()) {
        return;
      }
      this.loading = true;
      setVersion(this.version.name, this.iosVersion).then(() => {
        this.FETCH_VERSIONS().then(() => {
          this.dialog = false;
          this.loading = false;
        });
      });
    },
  },
};
</script>
