import moment from 'moment';
import INCIDENT_FIELDS from '@/enums/incidentFields';

function getEqCondition(commaSeparatedString, field) {
  const type = field === INCIDENT_FIELDS.SEQUENCE_NUMBER ? 'sequence_numbers' : 'incident_numbers';
  const values = commaSeparatedString
    .split(',')
    .map(s => s.trim())
    .filter(Boolean);
  return {
    type,
    field,
    values,
  };
}

/* eslint func-names:0 */
export default function convertFilters(filters) {
  if (!filters.dateRange
      && !filters.sequenceNumbers
      && !filters.incidentNumbers
      && !filters.dateRemoved
      && !filters.deviceId
  ) {
    return null;
  }
  const conditions = [];
  if (!filters.sequenceNumbers && !filters.incidentNumbers && filters.dateRange) {
    const condition = {
      type: 'range',
      field: INCIDENT_FIELDS.INCIDENT_DATE,
      gte: filters.dateRange[0],
      lte: null,
    };
    if (filters.dateRange.length === 2) {
      const lte = filters.dateRange[1];
      condition.lte = lte;
    }
    conditions.push(condition);
  }
  if (filters.sequenceNumbers) {
    conditions.push(getEqCondition(filters.sequenceNumbers, INCIDENT_FIELDS.SEQUENCE_NUMBER));
  }
  if (filters.incidentNumbers) {
    conditions.push(getEqCondition(filters.incidentNumbers, INCIDENT_FIELDS.INCIDENT_NUMBER));
  }
  if (filters.dateRemoved) {
    const gte = moment(`${filters.dateRemoved} 00:00:00`);
    const lte = moment(`${filters.dateRemoved} 23:59:59`);
    conditions.push({
      type: 'range',
      field: 'RemovedAt',
      gte: gte.utc().format(),
      lte: lte.utc().format(),
    });
  }
  if (filters.deviceId) {
    conditions.push({
      type: 'eq',
      field: 'DeviceId',
      value: filters.deviceId,
    });
  }
  if (filters.uploaded) {
    conditions.push({
      type: 'eq',
      field: 'Uploaded',
      value: filters.uploaded,
    });
  }
  return {
    operator: 'and',
    conditions,
  };
}
