<template>
  <v-card class="white elevation-3 ma-4 duplicate-incidents">
    <v-subheader>JSON Uploading</v-subheader>
    <json-uploader />
    <v-divider />
    <v-subheader>Duplicate Incidents</v-subheader>
    <v-row class="pl-4 pr-4">
      <v-col
        cols="12"
        sm="2"
      >
        <v-select
          v-model="filters.clientId"
          :items="clientFilterItems"
          :label="$t('duplicateIncidents.filters.selectClient')"
          :menu-props="{'minWidth': 260, 'maxWidth': 260}"
          item-value="registryId"
          item-text="name"
          single-line
        />
      </v-col>
      <v-col
          cols="12"
          sm="2"
      >
        <v-text-field
            v-model="filters.sequenceNumber"
            :label="$t('duplicateIncidents.filters.sequenceNumber')"
            single-line
        />
      </v-col>
    </v-row>
    <duplicate-incidents-table
      :items="entityState.items"
      :pagination="pagination"
      :total-items="entityState.pagination.totalItems"
      :loading="loading"
      @showIncident="showIncident"
      @update:pagination="updatePagination"
    />
    <duplicate-incident-popup
      ref="duplicateIncidentPopup"
      @showDiff="showDiff"
      @closeAfterAction="reloadItems"
    />
    <diff-popup
      ref="diffPopup"
      @close="showIncident"
    />
  </v-card>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import _ from 'lodash';
import { SET_TITLE } from '@/store/mutations';
import { LOAD_DUPLICATE_INCIDENTS } from '@/store/DuplicateIncidents/actions';
import {
  SET_DUPLICATE_INCIDENTS_FILTERS, SET_DUPLICATE_INCIDENTS_ITEMS,
  SET_DUPLICATE_INCIDENTS_PAGINATION,
} from '@/store/DuplicateIncidents/mutations';
import _debounce from 'lodash/debounce';
import DuplicateIncidentsTable from '../components/DuplicateIncidents/DuplicateIncidentsTable';
import DuplicateIncidentPopup from '../components/DuplicateIncidents/DuplicateIncidentPopup';
import JsonUploader from '../components/DuplicateIncidents/JsonUploader';
import DiffPopup from '../components/DuplicateIncidents/DiffPopup';

const { mapState, mapActions, mapMutations } = createNamespacedHelpers(
  'duplicateIncidents',
);

export default {
  components: {
    DuplicateIncidentPopup,
    DiffPopup,
    DuplicateIncidentsTable,
    JsonUploader,
  },
  data() {
    return {
      loading: false,
      debounceFunc: null,
    };
  },
  computed: {
    ...mapState({
      entityState: state => state,
    }),

    /**
     * Returns clients which needs to show in filter.
     *
     * @return {Array}
     */
    clients() {
      return this.$store.state.clientsList;
    },

    /**
     * Items for clients filter.
     *
     * @return {Array}
     */
    clientFilterItems() {
      const items = [];
      items.push({
        registryId: null,
        name: this.$t('duplicateIncidents.filters.allClients'),
      });
      return [...items, ...this.clients];
    },

    pagination: {
      get() {
        return this.entityState.pagination;
      },
      set(value) {
        if (!_.isEqual(value, this.entityState.pagination)) {
          this.setPagination(value);
          this.reloadItems();
        }
      },
    },

    /**
     * Filter params.
     *
     * @return {Object}
     */
    filters() {
      return this.entityState.filters;
    },
  },
  watch: {
    filters: {
      handler(newVal) {
        this.pagination.page = 1;
        this.setFilters(newVal);
        this.reloadItems();
      },
      deep: true,
    },
  },
  mounted() {
    if (this.$route.query.clientId || this.$route.query.sequenceNumber) {
      this.setFilters({
        clientId: this.$route.query.clientId ? parseInt(this.$route.query.clientId, 10) : null,
        sequenceNumber: this.$route.query.sequenceNumber ? this.$route.query.sequenceNumber : null,
      });
    } else {
      this.setFilters({
        clientId: null,
        sequenceNumber: null,
      });
    }
    this.setItems({
      items: [],
      totalItems: 0,
    });
    this.reloadItems();
    this.$store.commit(SET_TITLE, this.$t('duplicateIncidents.header'));
  },
  methods: {
    ...mapMutations({
      setPagination: SET_DUPLICATE_INCIDENTS_PAGINATION,
      setFilters: SET_DUPLICATE_INCIDENTS_FILTERS,
      setItems: SET_DUPLICATE_INCIDENTS_ITEMS,
    }),
    ...mapActions({
      loadItems: LOAD_DUPLICATE_INCIDENTS,
    }),

    updatePagination(newPagination) {
      this.pagination = newPagination;
    },

    /**
     * Reloads incidents paginated list for current filters
     */
    async reloadItems() {
      if (!this.debounceFunc) {
        const that = this;
        this.debounceFunc = _debounce(async () => {
          that.loading = true;
          try {
            await that.loadItems();
          } finally {
            that.loading = false;
          }
        }, 300);
      }
      this.debounceFunc();
    },

    /**
     * Show compare incidents popup.
     *
     * @param {Object} incident - Duplicated incident object.
     */
    showIncident(incident) {
      this.$refs.duplicateIncidentPopup.show(incident);
    },

    /**
     * Show diff incidents popup.
     *
     * @param {Object} incident - Duplicated incident object.
     */
    showDiff(incident) {
      this.$refs.diffPopup.show(incident);
    },
  },
};
</script>
