import { fetchVersions, fetchUrl } from '@/api/iosInstall';

import { SET_VERSIONS, SET_URL } from './mutations';

export const FETCH_VERSIONS = 'FETCH_PROVIDERS';
export const FETCH_URL = 'FETCH_URL';

export default {
  [FETCH_VERSIONS]: ({ commit }) => fetchVersions().then(data => {
    commit(SET_VERSIONS, data);
  }),
  [FETCH_URL]: ({ commit }) => fetchUrl().then(data => {
    commit(SET_URL, data.url);
  }),
};
