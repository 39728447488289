/* eslint func-names:0 */
export default {
  data() {
    return {
      errorAlert: false,
      errorMessage: null,
    };
  },
  methods: {
    hideError() {
      this.errorAlert = false;
      this.errorMessage = null;
    },
    showError(message) {
      this.errorMessage = message;
      this.errorAlert = true;
    },
    parseErrorResponse(response, showMessage = false) {
      if (response.status === 400 && response.data.errors !== undefined) {
        response.data.errors.forEach(error => {
          error.messages.forEach(message => {
            this.errors.add({
              field: error.field,
              msg: message,
            });
          });
        });
        if (showMessage) {
          this.showError(response.data.message);
        }
      } else if (response.data instanceof Blob) {
        const fr = new FileReader();
        const { showError } = this;
        fr.onload = function () {
          const data = JSON.parse(this.result);
          showError(data.message);
        };
        fr.readAsText(response.data);
      } else if (response.data) {
        this.showError(response.data.message);
      } else {
        this.showError('Unexpected server error');
      }
    },
  },
};
