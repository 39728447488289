<template>
  <div>
    <v-data-table
        :headers="header"
        :items="items"
        :options.sync="pagination"
        :server-items-length="totalItems"
        :height="tableHeight"
        :loading="loading"
        :footer-props="{
           itemsPerPageOptions: [10,25,50]
        }"
        no-data-text="No data available"
        no-results-text="No data available"
        fixed-header
        class="header-nowrap"
    >
      <template v-slot:item.II_SequenceNumber="{ item }">
        <a @click="$emit('goToIncident', item)">{{ item.II_SequenceNumber }}</a>
      </template>
      <template v-slot:item.location="{ item }">
        {{locationPrefix}}{{ item.searchType }}
      </template>
      <template v-slot:item.II_IncidentDate="{ item }">
        {{ item.II_IncidentDate | date }}
      </template>
      <template v-slot:item.datePCRUploaded="{ item }">
        {{ item.datePCRUploaded | date }}
      </template>
    </v-data-table>
  </div>

</template>

<script>
import _ from 'lodash';
import fixedHeader from '@/mixins/fixedHeader';

export default {
  name: 'IncidentsTable',
  mixins: [fixedHeader],
  props: {
    items: {
      type: Array,
      required: true,
    },
    totalItems: {
      type: Number,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    showLocationColumn: {
      type: Boolean,
      required: true,
    },
    locationPrefix: {
      type: String,
      required: false,
    },
    showFirstNameColumn: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      pagination: {
        page: 1,
        itemsPerPage: 25,
        sortBy: ['II_IncidentDate'],
        sortDesc: [true],
        groupBy: [],
        groupDesc: [],
        mustSort: false,
        multiSort: false,
        totalItems: 0,
      },
    };
  },
  computed: {
    header() {
      let header = [
        {
          text: 'Sequence Number',
          value: 'II_SequenceNumber',
          sortable: false,
        },
        {
          text: 'Incident Date',
          value: 'II_IncidentDate',
          sortable: false,
        },
        {
          text: 'PCR Uploaded',
          value: 'datePCRUploaded',
          sortable: false,
        },
        {
          text: 'Incident Number',
          value: 'II_IncidentNumber',
          sortable: false,
        },
        {
          text: 'First Name',
          value: 'PI_FirstName',
          sortable: false,
        },
        {
          text: 'Last Name',
          value: 'PI_LastName',
          sortable: false,
        },
        {
          text: 'Client',
          value: 'client',
          sortable: false,
        },
        {
          text: 'Location',
          value: 'location',
          sortable: false,
        },
      ];

      header = header.filter((item) => {
        if (!this.showFirstNameColumn && item.text === 'First Name') {
          return false;
        }

        if (!this.showLocationColumn && item.text === 'Location') {
          return false;
        }

        return true;
      });

      return header;
    },
  },
  watch: {
    /**
     * Watch pagination options and update pagination props.
     */
    pagination(newData, oldData) {
      if (!_.isEqual(newData, oldData)) {
        this.$emit('updatePagination', newData);
      }
    },
  },
};
</script>
