export const SET_VERSIONS = 'SET_VERSIONS';
export const SET_URL = 'SET_URL';

export default {
  [SET_VERSIONS]: (state, versions) => {
    state.versions = versions;
  },
  [SET_URL]: (state, url) => {
    state.url = url;
  },
};
