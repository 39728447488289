import advancedQuery from '@/lang/advancedQuery';
import general from './general';
import hospitals from './hospitals';
import hospitalTypes from './hospitalTypes';
import validation from './validation';
import admins from './admins';
import cadMonitoring from './cadMonitoring';
import templates from './templates';
import buttons from './buttons';
import documents from './documents';
import iosInstall from './iosInstall';
import apiAuditLog from './apiAuditLog';
import importedIncidents from './importedIncidents';
import flaggedUsers from './flaggedUsers';
import duplicateIncidents from './duplicateIncidents';
import pushNotifications from './pushNotifications';
import incidents from './incidents';
import releaseNotes from './releaseNotes';
import utilities from './utilities';
import clients from './clients';
import utilitiesExport from './utilitiesExport';
import users from './users';
import devices from './devices';
import externalServiceExports from './externalServiceExports';
import user from './user';
import firstRunDevices from './firstRunDevices';
import faxes from './faxes';
import profileSettings from './profileSettings';
import exportLog from './exportLog';

export default {
  advancedQuery,
  general,
  hospitals,
  hospitalTypes,
  validation,
  admins,
  cadMonitoring,
  templates,
  buttons,
  documents,
  iosInstall,
  apiAuditLog,
  importedIncidents,
  profileSettings,
  flaggedUsers,
  duplicateIncidents,
  pushNotifications,
  incidents,
  releaseNotes,
  utilities,
  clients,
  utilitiesExport,
  users,
  user,
  devices,
  externalServiceExports,
  firstRunDevices,
  faxes,
  exportLog,
};
