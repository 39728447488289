<template>
  <v-card
    class="elevation-3 ma-4"
    flat
  >
    <v-expansion-panels
      v-if="Object.keys(providers).length"
      class="cad-monitoring-providers"
    >
      <v-expansion-panel
        v-for="(provider, i) in providers"
        :key="i"
      >
        <v-expansion-panel-header>
          <div class="header">
            <v-chip
              :color="provider.status | statusColor"
              class="ma-1"
              text-color="white"
              small
              label
            >{{ provider.status | statusTitle }}</v-chip>:
            <span v-if="provider.name">Group: <b>{{ provider.name }}</b> |</span>
            <span v-else>Client: <b>{{ provider.clientName }}</b> |</span>
            CADType: <b>{{ provider.type | cadType }}</b> |
            Last File Processed @:
            <b>
              {{ provider.lastFileProcessedPST | formatDate }}
              {{ provider.lastFileProcessedPST ? siteInfo.timeZone : '' }}
            </b>
          </div>

          <Settings
            v-if="showSettings"
            class="mr-4"
            :client-id="provider.clientID"
          ></Settings>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-card flat>
            <v-card-text>
              <Provider
                :client-id="provider.clientID"
                :show-latest-cad-data-link="showLatestCadDataLink"
                :show-cloud-watch-url="showCloudWatchUrl"
                :show-failed-files="showFailedFiles"
              ></Provider>
            </v-card-text>
          </v-card>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-card>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment';
import Provider from './Provider';
import Settings from './Settings';

export default {
  name: 'Providers',
  components: {
    Provider,
    Settings,
  },
  filters: {
    statusColor(status) {
      const statuses = {
        0: 'green',
        1: 'orange',
        2: 'error',
        3: 'gray',
      };
      return statuses[status];
    },
    statusTitle(status) {
      const statuses = {
        0: 'OK',
        1: 'WARNING',
        2: 'ERROR',
        3: 'OFF',
      };
      return statuses[status];
    },
    formatDate(date) {
      return date ? `${moment(date).format('MM/DD/YYYY - HH:mm:ss')}` : 'None';
    },
    cadType(type) {
      const map = {
        sftp: 'SFTP',
        vpn: 'VPN (Remote Share)',
        http: 'HTTP POST',
      };
      return map[type];
    },
  },
  props: {
    providers: {
      type: Object,
      required: true,
    },
    showSettings: {
      type: Boolean,
      required: false,
      default: false,
    },
    showCloudWatchUrl: {
      type: Boolean,
      required: false,
      default: false,
    },
    showLatestCadDataLink: {
      type: Boolean,
      required: false,
      default: false,
    },
    showFailedFiles: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      siteInfo: state => state.siteInfo,
    }),
  },
};
</script>

<style lang="scss">
.cad-monitoring-providers .header {
  display: block;
}
</style>
