<template>
  <v-toolbar
      class="et3-export-submission-logs-toolbar"
      flat
  >
    <v-autocomplete
        v-if="showClientsFilter"
        v-model="filtersData.clientId"
        :items="clients"
        label="Choose client"
        clearable
        hide-details
        style="max-width: 300px"
        class="mr-5"
    />
    <v-text-field
        v-model="filtersData.sequenceNumber"
        hide-details
        class="mr-5"
        label="Sequence Number"
        style="max-width: 300px"
    />
    <v-select
        v-model="filtersData.status"
        :items="[
            {value: 'scheduled', text: 'Scheduled'},
            {value: 'queued', text: 'Queued'},
            {value: 'submitted', text: 'Submitted'},
            {value: 'failed', text: 'Failed'},
            {value: 'wait', text: 'Wait'},
        ]"
        hide-details
        label="Status"
        style="max-width: 300px"
        clearable
    />
    <v-spacer />
    <v-btn
        class="mr-3"
        color="primary"
        :disabled="showClientsFilter && !filtersData.clientId"
        @click="$emit('search', filtersData)"
    >
      Search
    </v-btn>
  </v-toolbar>
</template>
<script>

export default {
  name: 'SubmissionLogsToolbar',
  props: {
    filters: {
      type: Object,
      required: true,
    },
    showClientsFilter: {
      default: true,
      type: Boolean,
    },
  },
  data() {
    return {
      filtersData: this.filters,
    };
  },

  computed: {
    clients() {
      const { clientsList } = this.$store.state;
      if (!clientsList || !clientsList.length) {
        return [];
      }
      return clientsList.map(client => ({
        text: client.name,
        value: client.registryId,
      }));
    },
  },

  methods: {

  },
};
</script>

<style lang="scss">

</style>
