import api from './api';

export default {
  /**
   * Run export process.
   *
   * @param {Object} data - Data of export request
   *
   * @return Promise<Object>
   */
  runExport(data) {
    return api()
      .post('/nemsis-export/run', data)
      .then(response => response.data);
  },

  /**
   * Run submit process.
   *
   * @param {Object} data - Data of export request
   *
   * @return Promise<Object>
   */
  submit(data) {
    return api()
      .post('/nemsis-export/submit', data)
      .then(response => response.data);
  },

  /**
   * Get validation errors.
   *
   * @param {Object} params - Filter params
   *
   * @return Promise<Object>
   */
  validate(params) {
    return api()
      .get('/nemsis-export/validate', { params })
      .then(response => response.data);
  },

  /**
   * Get list of export settings.
   *
   * @param {string} type - Type of export.
   *
   * @return Promise<Object>
   */
  getSettings(type) {
    const params = {
      include: 'client',
    };
    return api()
      .get(`/nemsis-export/settings/${type}`, { params })
      .then(response => response.data);
  },

  /**
   * Save Nemsis export settings.
   *
   * @param {Object} clientId - Client Identifier
   * @param {Object} settings - Settings data
   *
   * @return Promise<Object>
   */
  createSettings(settings) {
    return api()
      .post('/nemsis-export/settings', settings)
      .then(response => response.data);
  },

  /**
   * Save Nemsis export settings.
   *
   * @param {Object} settingId - Setting Identifier
   * @param {Object} settings - Settings data
   *
   * @return Promise<Object>
   */
  saveSettings(settingId, settings) {
    return api()
      .put(`/nemsis-export/settings/${settingId}`, settings)
      .then(response => response.data);
  },

  /**
   * Delete Nemsis export settings.
   *
   * @return Promise<Object>
   */
  deleteSettings(settingId) {
    return api()
      .delete(`/nemsis-export/settings/${settingId}`)
      .then(response => response.data);
  },

  /**
   * Get export logs.
   *
   * @param {Object} params - Filter params
   *
   * @return Promise<Object>
   */
  getLogs(params) {
    return api()
      .get('/nemsis-export/logs', { params })
      .then(response => response.data);
  },

  /**
   * Retry failed export.
   *
   * @param {Number} exportId - Export identifier.
   *
   * @return Promise<Object>
   */
  retryExport(exportId) {
    return api()
      .post(`/nemsis-export/${exportId}/retry`)
      .then(response => response.data);
  },
};
