export default {
  header: 'Push Notifications',
  tableHeaders: {
    deliveryDate: 'Delivery date',
    messageType: 'Message Type',
    showType: 'Show Type',
    client: 'Client',
    device: 'Device',
    received: 'Received',
    message: 'Message',
    error: 'Error',
  },
  filters: {
    allClients: 'All Clients',
    selectClient: 'Select Client',
    deliveryDate: 'Delivery Date',
  },
  editPopup: {
    headerNew: 'New Push Notification',
    headerEdit: 'Edit Push Notification',
    messageType: 'Message Type',
    showType: 'Deliver and Show',
    newAppVersion: 'New App version is available',
    newTemplate: 'New Template is available',
    url: 'Please visit web-site',
    custom: 'Custom',
    showTypeDashboard: 'On Dashboard (when first time opened)',
    showTypeImmediate: 'Immediate (on any screen)',
    allClients: 'All Clients',
    allDevices: 'All Devices',
    toClient: 'To Client',
    toDevice: 'To Device',
    message: 'Message',
    scheduledAt: 'Scheduled At',
    urlAddrress: 'URL',
  },
  buttons: {
    sendNow: 'Send Now',
    schedule: 'Schedule',
  },
  onDashboard: 'On Dashboard',
  immediate: 'Immediate',
  newTemplate: 'New Template',
  newAppVersion: 'New App version',
  pleaseVisitWebsite: 'Please visit web-site',
  custom: 'Custom',
  notDelivered: 'Not Delivered',
  processing: 'Processing',
  deleteConfirmationTitle: 'Delete Push Notification',
  deleteConfirmationText: 'Do you really want to delete push notification?',
};
