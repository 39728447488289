import api from './api';

/**
 * Fetch list of iOS versions
 */
export function fetchVersions() {
  return api()
    .get('/ios-versions')
    .then(response => response.data);
}

/**
 * Fetch URL to iOS install page
 */
export function fetchUrl() {
  return api()
    .get('/ios-versions/url')
    .then(response => response.data);
}

/**
 * Install specific version
 */
export function setVersion(version, iosVersion) {
  return api()
    .post('/ios-versions/set-version', {
      name: version,
      iosVersion,
    })
    .then(response => response.data);
}

/**
 * Delete specific version
 */
export function deleteVersion(version) {
  return api()
    .delete(`/ios-versions?name=${version}`)
    .then(response => response.data);
}

/**
 * Get current installed versions.
 *
 * @return Promise
 */
export function currentVersion() {
  return api()
    .get('ios-versions/current')
    .then(response => response.data);
}
