<template>
  <div>
    <v-card
        elevation="3"
        class="ma-4"
    >
      <v-tabs v-model="tabIndex">
        <v-tab
            v-for="(item, index) in tabItems"
            :key="item.name"
            :tab-value="index"
            exact
            active-class="primary--text"
            class="subheading text-capitalize tab-item"
        >
          {{ item.name }}
        </v-tab>
      </v-tabs>
      <v-divider class="mb-5"/>

      <toolbar
          v-if="tabItems[tabIndex].id === 'export'"
          :client-id.sync="clientId"
          :exporting="exporting"
          :preparing="preparing"
          :show-clients-filter="showClientsFilter"
          :show-submit-btn="canSubmit"
          @export="runExport"
          @submit="submit"
          @cancel="cancelCurrentExport"
      />
      <toolbar-validate
          v-if="tabItems[tabIndex].id === 'validate'"
          ref="toolbarValidate"
          :validating="validating"
          :show-clients-filter="showClientsFilter"
          @validate="runValidate"
      />
      <submission-logs-toolbar
          v-if="tabItems[tabIndex].id === 'logs'"
          ref="toolbarSubmissionLogs"
          :filters="submissionLogsFilters"
          :show-clients-filter="showClientsFilter"
          @search="searchLogs"
      />
    </v-card>

    <v-card
        class="white elevation-3 ma-4 nemsis-export-vcart"
        style="min-height: 50px;"
    >
      <v-tabs-items :value="tabIndex">
        <v-tab-item>
          <v-card-text
              v-if="!error && !exporting && !urlToDownload"
              class="text-center"
          >
            There isn't export result.
          </v-card-text>

          <v-card-text
              v-if="error"
              class="text-center export-result-error"
          >
            {{ error }}
          </v-card-text>

          <export-results
              v-if="exporting || urlToDownload"
              :export-progress="progress"
              :incidents-failed-list="incidentsFailed"
              :incidents-processed-list="incidentsProcessed"
              :incidents-processing-list="incidentsProcessing"
              :url-to-download="urlToDownload"
              @validate="goToValidate"
          />

        </v-tab-item>
        <v-tab-item>
          <v-card-text
              v-if="errorValidate"
              class="text-center export-result-error"
          >
            {{ errorValidate }}
          </v-card-text>
          <validate-results :validation-result="validationResult"/>
        </v-tab-item>
        <v-tab-item v-if="showSettingsPage">
          <v-toolbar flat>
            <v-toolbar-title>Export Settings</v-toolbar-title>
            <v-spacer />
            <v-btn
                color="primary"
                dark
                class="mb-2"
                @click="addSettings"
            >+ ADD
            </v-btn>
          </v-toolbar>
          <settings-table
              :items="settings"
              :loading="loading"
              @editSetting="editSettings"
          />
        </v-tab-item>
        <v-tab-item>
          <submission-log-table
              ref="submissionLogsTable"
              :type="type"
              :filters="submissionLogsFilters"
              :uses-clients-filter="showClientsFilter"
              :use-impersonate="useImpersonate"
          />
        </v-tab-item>
      </v-tabs-items>
    </v-card>
    <settings-popup
        ref="settingsPopup"
        :all-settings="settings"
        :type="type"
        @saved="fetchSettings"
    />
    <confirmation ref="confirmationPopup"></confirmation>
  </div>
</template>

<script>
import _ from 'lodash';
import { SHOW_SNACKBAR } from '@/store/actions';
import { mapActions } from 'vuex';
import Toolbar from '@/components/NemsisExport/Toolbar';
import nemsisExport from '@/api/nemsisExport';
import ExportResults from '@/components/NemsisExport/ExportResults';
import ToolbarValidate from '@/components/NemsisExport/ToolbarValidate';
import ValidateResults from '@/components/NemsisExport/ValidateResult';
import SettingsTable from '@/components/NemsisExport/SettingsTable';
import SettingsPopup from '@/components/NemsisExport/SettingsPopup';
import SubmissionLogTable from '@/components/NemsisExport/SubmissionLogsTable';
import SubmissionLogsToolbar from '@/components/NemsisExport/SubmissionLogsToolbar';
import Confirmation from '@/components/Confirmation';
import NEMSIS_EXPORT_TYPES from '../../enums/nemsisExportTypes';

export default {
  components: {
    Confirmation,
    SubmissionLogsToolbar,
    SubmissionLogTable,
    SettingsPopup,
    SettingsTable,
    ValidateResults,
    ToolbarValidate,
    ExportResults,
    Toolbar,
  },
  props: {
    type: {
      type: String,
      required: true,
    },
    showSettingsPage: {
      type: Boolean,
      required: true,
    },
    showClientsFilter: {
      type: Boolean,
      required: true,
    },
    useImpersonate: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      tabIndex: 0,
      clientId: null,
      loading: false,
      exporting: false,
      preparing: false,
      validating: false,
      error: null,
      errorValidate: null,
      validationResult: {},
      processId: null,
      progress: 0,
      incidentsInQueue: {},
      incidentsFailed: [],
      incidentsProcessed: [],
      urlToDownload: null,
      settings: [],
      submissionLogsFilters: {
        clientId: null,
        sequenceNumber: null,
        status: null,
      },
      exportTypes: NEMSIS_EXPORT_TYPES,
    };
  },
  computed: {
    tabItems() {
      const tabItems = [
        { id: 'export', name: 'Export' },
        { id: 'validate', name: 'Validate' },
      ];
      if (this.showSettingsPage) {
        tabItems.push({ id: 'settings', name: 'Export Settings' });
      }
      tabItems.push({ id: 'logs', name: 'Submission Logs' });
      return tabItems;
    },

    echoChannel() {
      return `nemsis-export.${this.processId}`;
    },

    incidentsProcessing() {
      return Object.values(this.incidentsInQueue);
    },

    settingsByClientId() {
      const items = {};
      this.settings.forEach(setting => {
        items[setting.clientId] = setting;
      });
      return items;
    },

    canSubmit() {
      if (this.showSettingsPage) {
        return this.clientId
            && Object.prototype.hasOwnProperty.call(this.settingsByClientId, this.clientId);
      }
      const { siteInfo } = this.$store.state;
      const fieldToCheck = `hasNemsis${this.capitalize(this.type)}Setting`;
      return siteInfo && siteInfo[fieldToCheck];
    },
  },
  mounted() {
    if (this.showSettingsPage) {
      this.fetchSettings();
    }
  },
  methods: {
    ...mapActions({
      showSnackbar: SHOW_SNACKBAR,
    }),

    capitalize(s) {
      return s[0].toUpperCase() + s.slice(1);
    },

    async fetchSettings() {
      try {
        this.loading = true;
        this.settings = await nemsisExport.getSettings(this.type);
      } finally {
        this.loading = false;
      }
    },

    async submit(filters) {
      const params = filters;
      params.type = this.type;
      this.$refs.confirmationPopup.showConfirm(
        'Submit confirmation.',
        'Are you sure you want to submit incidents?',
        async () => {
          const response = await nemsisExport.submit(filters);
          this.showSnackbar(`${response.countIncidents} incidents was scheduled to export. Please see Submission Logs tab`);
        },
      );
    },

    async runExport(filters) {
      try {
        const params = filters;
        params.type = this.type;
        this.preparing = true;
        const response = await nemsisExport.runExport(params);
        this.error = null;
        this.progress = 0;
        this.processId = response.processId;
        this.incidentsInQueue = this.getAllIncidentsInQueue(response.incidents);
        this.incidentsFailed = [];
        this.incidentsProcessed = [];
        this.urlToDownload = null;
        this.exporting = true;
        this.listenExportProgress();
        this.listenExportCompleted();
        this.listenExportError();
      } catch (error) {
        const { response } = error;
        if ((response.status === 400 || response.status === 404) && response.data.message) {
          this.error = response.data.message;
        } else {
          this.error = 'Something wrong, please try again later.';
        }
      } finally {
        this.preparing = false;
      }
    },

    async runValidate(filters) {
      try {
        const params = filters;
        params.type = this.type;
        this.validating = true;
        this.validationResult = {};
        this.validationResult = await nemsisExport.validate(params);
      } catch (error) {
        const { response } = error;
        if (response.status === 404) {
          this.errorValidate = 'Incident not found.';
        } else {
          this.errorValidate = 'Something wrong, please try again later.';
        }
      } finally {
        this.validating = false;
      }
    },

    getAllIncidentsInQueue(incidents) {
      const items = {};
      if (incidents) {
        incidents.forEach(incident => {
          items[incident.id] = incident;
        });
      }
      return items;
    },

    cancelCurrentExport() {
      this.exporting = false;
      this.error = null;
      this.urlToDownload = null;
      this.incidentsInQueue = {};
      this.incidentsFailed = [];
      this.incidentsProcessed = [];
      this.$echo.leave(this.echoChannel);
    },

    listenExportProgress() {
      this.$echo.private(this.echoChannel).listen('.ExportProgressUpdated', (data) => {
        this.progress = data.progress;
        this.sortIncidents(data.incidents);
      });
    },
    listenExportCompleted() {
      this.$echo.private(this.echoChannel).listen('.ExportCompleted', (data) => {
        this.urlToDownload = data.url;
        this.exporting = false;
        this.$echo.leave(this.echoChannel);
      });
    },
    listenExportError() {
      this.$echo.private(this.echoChannel).listen('.ExportError', (data) => {
        this.error = data.message;
        this.$echo.leave(this.echoChannel);
      });
    },

    sortIncidents(incidents) {
      const allIncidents = _.cloneDeep(this.incidentsInQueue);
      if (incidents) {
        _.forEach(incidents, (status, incidentId) => {
          if (!Object.prototype.hasOwnProperty.call(allIncidents, incidentId)) {
            return;
          }
          const incident = allIncidents[incidentId];
          if (status === 'success') {
            this.incidentsProcessed.push(incident);
          } else if (status === 'invalid') {
            incident.status = 'invalid';
            this.incidentsFailed.push(incident);
          } else if (typeof status === 'object' && status.status === 'error') {
            incident.status = 'error';
            incident.message = status.message;
            this.incidentsFailed.push(incident);
          }
          delete allIncidents[incidentId];
        });
        this.incidentsInQueue = allIncidents;
      }
    },
    goToValidate(sequenceNumber, clientId) {
      this.tabIndex = 1;
      this.$refs.toolbarValidate.validate(clientId, sequenceNumber);
    },

    /**
     * Show add settings popup.
     */
    addSettings() {
      this.$refs.settingsPopup.show(null);
    },

    editSettings(settings) {
      this.$refs.settingsPopup.show(settings);
    },

    searchLogs(filters) {
      this.submissionLogsFilters = filters;
      this.$refs.submissionLogsTable.fetchData();
    },
  },
};
</script>

<style lang="scss">
.export-result-error {
  color: red !important;
}
</style>
