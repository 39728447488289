<template>
  <v-dialog
      v-model="dialog"
      max-width="600px"
      persistent
  >
    <v-card v-if="loading">
      <v-card-text class="mt-0 pt-3">
        Loading...
        <v-progress-linear
            indeterminate
            color="primary"
            class="mb-0"
        ></v-progress-linear>
      </v-card-text>
    </v-card>
    <v-card v-else>
      <v-card-title>
        <span>{{ isNew ? 'Add Provider' : 'Edit Provider' }}</span>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text
          v-if="dialog"
          class="pb-0"
      >
        <v-alert
            v-if="errorAlert"
            type="error"
            outlined
            transition="scale-transition"
            class="mt-2"
        >
          {{ errorMessage }}
        </v-alert>

        <v-layout wrap>
          <v-flex xs12>
            <v-text-field
                v-model="provider.numberId"
                v-validate="{ required: true, regex: numberIdRegExp }"
                :error-messages="errors.collect('numberId')"
                label="Number Id"
                name="numberId"
            />
          </v-flex>
          <v-flex xs12>
            <v-text-field
                v-model="provider.name"
                :error-messages="errors.collect('name')"
                label="Name"
                name="name"
            />
          </v-flex>
        </v-layout>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer />
        <v-btn
            text
            color="blue darken-1"
            @click="dialog = false"
        >
          Cancel
        </v-btn>
        <v-btn
            text
            color="primary"
            :loading="saving"
            @click="validateAndSave"
        >
          <template>Save</template>
        </v-btn>
      </v-card-actions>
    </v-card>
    <update-entries-confirmation
        v-if="providerId"
        ref="updateEntriesDialog"
        :old-number="origNumberId"
        :new-number="provider.numberId"
    />
  </v-dialog>
</template>
<script>
import _ from 'lodash';
import errorAlertMixins from '@/mixins/errorAlertMixins';
import ceHoursSettings from '@/api/ceHoursSettings';
import UpdateEntriesConfirmation from '@/components/CeEntry/Settings/UpdateEntriesConfirmation';

export default {
  name: 'EditProviderPopup',
  components: { UpdateEntriesConfirmation },
  mixins: [errorAlertMixins],
  data() {
    return {
      dialog: false,
      loading: false,
      isNew: true,
      saving: false,
      providerId: null,
      origNumberId: null,
      hasEntries: false,
      provider: {
        numberId: null,
        name: null,
      },
      dictionary: {
        attributes: {
          numberId: 'Number Id',
          name: 'Name',
        },
        custom: {
          numberId: {
            regex: () => 'Invalid format, the allowed format is XX-XXXX',
          },
        },
      },
      numberIdRegExp: new RegExp('^[0-9]{2}-[0-9]{4}$'),
    };
  },

  mounted() {
    this.$validator.localize('en', this.dictionary);
  },

  methods: {
    /**
     * Show dialog.
     */
    async show(provider = null) {
      this.loading = true;
      this.dialog = true;
      this.isNew = true;
      this.providerId = null;
      this.origNumberId = null;
      this.hasEntries = false;
      const providerData = {
        numberId: null,
        name: null,
      };
      if (provider) {
        this.isNew = false;
        this.providerId = provider.id;
        providerData.numberId = provider.numberId;
        providerData.name = provider.name;
        this.origNumberId = provider.numberId;
        this.hasEntries = provider.entries > 0;
      }
      this.provider = providerData;
      this.loading = false;
      this.hideError();
      this.errors.clear();
      await this.$validator.reset();
    },

    async validateAndSave() {
      this.hideError();
      await this.$validator.reset();
      if (!await this.$validator.validateAll()) {
        return;
      }
      const data = _.cloneDeep(this.provider);
      if (this.providerId && this.origNumberId !== data.numberId && this.hasEntries) {
        this.$refs.updateEntriesDialog.showConfirm(async () => {
          data.isUpdateEntities = true;
          await ceHoursSettings.updateProvider(this.providerId, data);
          this.dialog = false;
          this.$emit('saved');
        }, async () => {
          data.isUpdateEntities = false;
          await ceHoursSettings.updateProvider(this.providerId, data);
          this.dialog = false;
          this.$emit('saved');
        });
        return;
      }
      this.saving = true;
      try {
        if (this.providerId) {
          await ceHoursSettings.updateProvider(this.providerId, data);
        } else {
          await ceHoursSettings.createProvider(data);
        }
        this.dialog = false;
        this.$emit('saved');
      } catch (e) {
        this.parseErrorResponse(e.response, false);
      } finally {
        this.saving = false;
      }
    },
  },
};
</script>
