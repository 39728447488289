<template>
  <div>
    Select iOS App version which you want to provide to public on: <a
      :href="url"
      target="_blank"
  >{{ url }}</a>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { FETCH_URL } from '@/store/IosInstall/actions';

export default {
  name: 'PageUrl',
  data() {
    return {};
  },
  computed: {
    ...mapState('iosInstall', ['url']),
  },
  created() {
    this.FETCH_URL();
  },
  methods: {
    ...mapActions({
      FETCH_URL: `iosInstall/${FETCH_URL}`,
    }),
  },
};
</script>
