<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="650px"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        depressed
        small
        max-width="186"
        class="justify-space-between mr-2"
        v-on="on"
      >
        {{ providers[clientId] | settingsBtnTitle }}
        <v-icon right>settings</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="headline">CAD Import and Monitoring Settings</span>
      </v-card-title>
      <v-card-text>
        <v-layout wrap>
          <v-flex xs12>
            <v-checkbox
              v-model="ready"
              :hide-details="true"
              label="CAD Import Ready"
            ></v-checkbox>
          </v-flex>
          <v-flex
            v-if="type !== 'http'"
            xs12
          >
            <v-select
              v-model="pollingInterval"
              :items="pollingIntervals"
              label="Polling Interval"
            ></v-select>
          </v-flex>

          <v-flex xs6>
            <v-select
              v-model="heartBeatFileReceivedPeriod"
              :items="heartBeatPeriods"
              label="HeartBeat"
            ></v-select>
          </v-flex>
          <v-flex
            xs6
            class="mb-4"
          >
            <v-select
              v-model="heartBeatFileProcessedPeriod"
              :items="heartBeatPeriods"
              label="Zero Kb (0 kb) file HeartBeat"
            ></v-select>
          </v-flex>

          <v-flex
            :class="[$style.mb20]"
            xs12
          >
            <span><b>Digital EMS Emails</b> (for Polling and HeartBeat issues notifications):
            <br>{{ providers[clientId].systemNotificationEmails }}</span>
          </v-flex>

          <v-flex xs12>
            <v-text-field
              v-model="heartBeatNotificationEmails"
              label="Provider Emails (for HeartBeat issues notifications)"
            />
          </v-flex>
        </v-layout>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn
          color="blue darken-1"
          text
          @click="dialog = false"
        >Cancel</v-btn>
        <v-btn
          :loading="loading"
          :disabled="loading"
          color="primary"
          text
          @click="updateProvider"
        >Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { updateProvider } from '@/api/cadMonitoring';
import { UPDATE_PROVIDER } from '@/store/CadMonitoring/actions';
import heartBeatPeriods from '@/data/Dropdowns/heartBeatPeriods';
import pollingIntervals from '@/data/Dropdowns/pollingIntervals';

export default {
  name: 'Provider',
  filters: {
    settingsBtnTitle(provider) {
      if (!provider.ready) {
        return 'Ready: OFF';
      }

      let processedPeriod = 'OFF';
      if (provider.heartBeatFileProcessedPeriod) {
        processedPeriod = provider.heartBeatFileProcessedPeriod > 60
          ? `${provider.heartBeatFileProcessedPeriod / 60}h`
          : `${provider.heartBeatFileProcessedPeriod}m`;
      }

      let receivedPeriod = 'OFF';
      if (provider.heartBeatFileReceivedPeriod) {
        receivedPeriod = provider.heartBeatFileReceivedPeriod > 60
          ? `${provider.heartBeatFileReceivedPeriod / 60}h`
          : `${provider.heartBeatFileReceivedPeriod}m`;
      }
      return `HeartBeat: ${receivedPeriod}/${processedPeriod}`;
    },
  },
  props: {
    clientId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      heartBeatPeriods,
      pollingIntervals,
      loading: false,
      dialog: false,
      type: this.$store.state.cadMonitoring.providers[this.clientId].type,
      ready: this.$store.state.cadMonitoring.providers[this.clientId].ready,
      heartBeatFileProcessedPeriod: this.$store.state.cadMonitoring.providers[
        this.clientId
      ].heartBeatFileProcessedPeriod,
      heartBeatFileReceivedPeriod: this.$store.state.cadMonitoring.providers[
        this.clientId
      ].heartBeatFileReceivedPeriod,
      monitoringEnabled: this.$store.state.cadMonitoring.providers[
        this.clientId
      ].monitoringEnabled,
      heartBeatNotificationEmails: this.$store.state.cadMonitoring.providers[
        this.clientId
      ].heartBeatNotificationEmails,
      pollingInterval: this.$store.state.cadMonitoring.providers[this.clientId]
        .pollingInterval,
    };
  },
  computed: {
    ...mapState('cadMonitoring', ['providers']),
  },
  methods: {
    ...mapActions({
      UPDATE_PROVIDER: `cadMonitoring/${UPDATE_PROVIDER}`,
    }),
    updateProvider() {
      this.$validator.validateAll().then(isValid => {
        if (!isValid) {
          return;
        }
        const updatedData = {
          ready: this.ready ? 1 : 0,
          pollingInterval: this.pollingInterval,
          heartBeatFileProcessedPeriod: this.heartBeatFileProcessedPeriod,
          heartBeatFileReceivedPeriod: this.heartBeatFileReceivedPeriod,
          heartBeatNotificationEmails: this.heartBeatNotificationEmails,
        };
        this.loading = true;
        updateProvider(this.clientId, updatedData).then(() => {
          this.UPDATE_PROVIDER({ clientID: this.clientId }).then(() => {
            this.loading = false;
            this.dialog = false;
          });
        });
      });
    },
  },
};
</script>

<style lang="scss" module>
.mb20 {
  margin-bottom: 20px;
}
</style>
