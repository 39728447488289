import api from './api';

export default {
  /**
   * Fetch list of incidents.
   *
   * @param {Object} params - Request params
   *
   * @return Promise<Object>
   */
  fetchList(params) {
    return api()
      .post('/client/incidents/search', params)
      .then(response => response.data);
  },

  /**
   * Export search results and download file
   *
   * @param {Object} params - Request params
   *
   * @return Promise<Object>
   */
  exportSearchResults(params) {
    return api()
      .post('/client/incidents/search/export', params, { responseType: 'blob' })
      .then(response => response);
  },

  /**
   * Export search results and send result to email.
   *
   * @param {Object} params - Request params
   *
   * @return Promise<Object>
   */
  exportSearchResultsToEmail(params) {
    return api()
      .post('/client/incidents/search/export-to-email', params)
      .then(response => response.data);
  },

  /**
   * Fetch list of table fields.
   *
   * @return Promise<Object>
   */
  fetchTableFields() {
    return api()
      .get('/client/incidents/search/table-fields')
      .then(response => response.data);
  },

  /**
   * Get incident duplications.
   *
   * @param {Object} params
   *
   * @return Promise<Object>
   */
  getDuplicates(params) {
    return api()
      .get('/client/incidents/search/duplicates', { params })
      .then(response => response.data);
  },

  /**
   * Fetch list of export fields.
   *
   * @return Promise<Object>
   */
  fetchExportFields() {
    return api()
      .get('/client/incidents/search/export-fields')
      .then(response => response.data);
  },

  /**
   * Fetch list of search fields.
   *
   * @return Promise<Object>
   */
  fetchSearchFields() {
    return api()
      .get('/client/incidents/search/search-fields')
      .then(response => response.data);
  },

  /**
   * Create incident by uploaded json.
   *
   * @param {Object} params - Params to create incident
   *
   * @return Promise
   */
  createByJson(params) {
    return api()
      .post('incidents', params)
      .then(response => response.data);
  },

  /**
   * Fetch Incident data.
   *
   * @param {string} incidentId - Identifier of incident
   *
   * @return Promise<Object>
   */
  fetchIncidentData(incidentId) {
    return api()
      .get(`incidents/${incidentId}/data`)
      .then(response => response.data);
  },

  /**
   * Fetch Incident.
   *
   * @param {string} incidentId - Identifier of incident
   * @param {Object} params
   *
   * @return Promise<Object>
   */
  fetchIncident(incidentId, params = null) {
    return api()
      .get(`incidents/${incidentId}`, { params })
      .then(response => response.data);
  },
  /**
   * Get list of fields which changed by system converters.
   *
   * @param {string} incidentId - Identifier of incident
   *
   * @return Promise<Object>
   */
  getSystemChangedFields(incidentId) {
    return api()
      .get(`client/incidents/${incidentId}/system-changes`)
      .then(response => response.data);
  },

  /**
   * Get list of fields which changed by system converters.
   *
   * @param {Number} incidentId - Identifier of incident
   *
   * @return Promise<Object>
   */
  getTeamMembers(incidentId) {
    return api()
      .get(`client/incidents/${incidentId}/team-members`)
      .then(response => response.data);
  },

  /**
   * Handle for run PDF regeneration.
   *
   * @param {string} incidentId - Identifier of incident
   *
   * @return Promise<Object>
   */
  regeneratePdf(incidentId) {
    return api()
      .post(`client/incidents/${incidentId}/regenerate-pdf`)
      .then(response => response.data);
  },

  /**
   * Handle for run PDF regeneration ignoring js errors.
   *
   * @param {string} incidentId - Identifier of incident
   *
   * @return Promise<Object>
   */
  regeneratePdfIgnoreErrors(incidentId) {
    return api()
      .post(`client/incidents/${incidentId}/regenerate-pdf-ignore-errors`)
      .then(response => response.data);
  },

  /**
   * Handle for run PDF using images.
   *
   * @param {string} incidentId - Identifier of incident
   *
   * @return Promise<Object>
   */
  regenerateFromImages(incidentId) {
    return api()
      .post(`client/incidents/${incidentId}/generate-from-images`)
      .then(response => response.data);
  },

  /**
   * Patch incident.
   *
   * @param {string} incidentId - Identifier of incident
   * @param {Object} data - Data to patch
   *
   * @return Promise<Object>
   */
  patch(incidentId, data) {
    return api().patch(`/incidents/${incidentId}`, data);
  },

  /**
   * Commit incident to main db.
   *
   * @param {Number} incidentId - Identifier of incident
   * @param {Boolean} deleteOnDevice - If there is a need to delete incidents from device
   *
   * @return Promise<Object>
   */
  commitIncidentToMainDb(incidentId, deleteOnDevice) {
    return api()
      .post(`client/incidents/${incidentId}/commit-to-main-db`, { deleteOnDevice: deleteOnDevice ? 1 : 0 })
      .then(response => response.data);
  },

  /**
   * Download JSON
   *
   * @param {string} incidentId - Identifier of incident
   *
   * @return Promise<Object>
   */
  downloadJson(incidentId) {
    return api()
      .get(`client/incidents/${incidentId}/json`, { responseType: 'blob' })
      .then(response => response);
  },

  /**
   * Download HTML
   *
   * @param {string} incidentId - Identifier of incident
   *
   * @return Promise<Object>
   */
  downloadHtml(incidentId) {
    return api()
      .get(`client/incidents/${incidentId}/html`, { responseType: 'blob' })
      .then(response => response);
  },

  /**
   * Download Nemsis zip file.
   *
   * @param {string} incidentId - Identifier of incident
   *
   * @return Promise<Object>
   */
  downloadNemsis(incidentId) {
    return api()
      .get(`client/incidents/${incidentId}/nemsis-zip`, { responseType: 'blob' })
      .then(response => response);
  },

  /**
   * Get list of pdfs.
   *
   * @param {string} incidentId - Identifier of incident
   *
   * @return Promise<Object>
   */
  getPdfs(incidentId) {
    return api()
      .get(`incidents/${incidentId}/pdfs`)
      .then(response => response.data);
  },

  /**
   * Save incident data.
   *
   * @param {string} incidentId - Identifier of incident
   * @param {Object} data
   *
   * @return Promise<Object>
   */
  saveIncidentData(incidentId, data) {
    return api()
      .post(`client/incidents/${incidentId}/data`, data)
      .then(response => response.data);
  },

  /**
   * Run command to validate data.
   *
   * @param {string} incidentId - Identifier of incident
   * @param {Object} data
   *
   * @return Promise<Object>
   */
  validateIncidentData(incidentId, data) {
    return api()
      .post(`client/incidents/${incidentId}/validate-data`, data)
      .then(response => response.data);
  },

  /**
   * Fetch Incident.
   *
   * @param {string} incidentId - Identifier of incident
   *
   * @return Promise<Object>
   */
  fetchTransmissionHistory(incidentId) {
    return api()
      .get(`client/incidents/${incidentId}/transmission-history`)
      .then(response => response.data);
  },

  /**
   * Get incident fields.
   *
   * @param {string} incidentId - Identifier of incident
   *
   * @return Promise
   */
  fetchIncidentFields(incidentId) {
    return api()
      .get(`/client/incidents/${incidentId}/fields`)
      .then(response => response.data);
  },

  /**
   * Get incident json file.
   *
   * @param {string} incidentId - Identifier of incident
   *
   * @return Promise
   */
  fetchJson(incidentId) {
    return api()
      .get(`incidents/${incidentId}/json`, { responseType: 'blob' })
      .then(response => response);
  },

  /**
   * Delete incident
   *
   * @param {string} incidentId - Identifier of incident
   * @param {Boolean} deleteOnDevice - If there is a need to delete incidents from device
   *
   * @return Promise
   */
  delete(incidentId, deleteOnDevice) {
    return api()
      .delete(`client/incidents/${incidentId}`, { params: { deleteOnDevice: deleteOnDevice ? 1 : 0 } })
      .then(response => response);
  },

  /**
   * Add print log.
   *
   * @param {Number} incidentId - Incident identifier
   * @param {Object} params - Request params
   *
   * @return Promise<Object>
   */
  printLog(incidentId, params) {
    return api().post(`incidents/${incidentId}/log/print`, params);
  },

  /**
   * Add view log.
   *
   * @param {Number} incidentId - Incident identifier
   *
   * @return Promise<Object>
   */
  viewLog(incidentId) {
    return api().post(`incidents/${incidentId}/log/view`);
  },

  /**
   * Add download log.
   *
   * @param {Number} incidentId - Incident identifier
   *
   * @return Promise<Object>
   */
  downloadLog(incidentId) {
    return api().post(`incidents/${incidentId}/log/download`);
  },

  /**
   * Fetch incident files.
   *
   * @param {Number} incidentId - Identifier of incident
   *
   * @returns Promise<Object>
   */
  fetchFiles(incidentId) {
    return api()
      .get(`client/incidents/${incidentId}/files`)
      .then(response => response.data);
  },

  /**
   * Save new file for incident.
   *
   * @param {Number} incidentId - Identifier of incident
   * @param {Object} params - Params for saving
   *
   * @returns {Promise<Object>}
   */
  createFile(incidentId, params) {
    return api().post(`client/incidents/${incidentId}/files`, params);
  },

  /**
   * Remove incident file.
   *
   * @param {Number} incidentId - Identifier of incident
   * @param {Object} params - Params for removing
   *
   * @returns {Promise<Object>}
   */
  removeFile(incidentId, params) {
    return api()
      .delete(`client/incidents/${incidentId}/files/${params.id}`)
      .then(response => response);
  },

  /**
   * Update incident file.
   *
   * @param {Number} incidentId - Identifier of incident
   * @param {Object} params - Param for updating
   *
   * @return {Promise<Object>}
   */
  updateFile(incidentId, params) {
    return api()
      .put(`client/incidents/${incidentId}/files/${params.id}`, params)
      .then(response => response);
  },
};
